import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";

import Header from "../components/header";
import Footer from "../components/footer";

import { GlobalStyles } from "./global-styles";
import { LayoutStyle } from "../styles/layouts";


const Layout = (props) => {

  useEffect(() => {
    if (props.isscrolllock) {
      const scrollY = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }
  }, [props.isscrolllock]);

  const elemProps = {...props};
  delete elemProps.isscrolllock;

  return (
    <div css={LayoutStyle} {...elemProps}>
      <Global styles={GlobalStyles} />
      <Header />
      {props.children}
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;


