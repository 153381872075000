/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import queryString from "query-string";

function SEO({ description, lang, meta, title, ogpImage }) {

  const { site, allWpScreenshot } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            lang
            title
            name
            siteUrl
            description
            author
            image
          }
        }
        allWpScreenshot(limit: 1, sort: {order: DESC, fields: date}, filter: {}) {
          edges {
            node {
              title
              date
              screenshot {
                mainscreenshot {
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    `
  )

  const metaLang = lang || site.siteMetadata.lang;
  const metaDescription = description || site.siteMetadata.description;

  const parseImageURL = queryString.parseUrl(allWpScreenshot.edges[0].node.screenshot.mainscreenshot.mediaItemUrl);
  parseImageURL.query.mark = 'https://asxiv-wp.imgix.net/watermark.png';
  parseImageURL.query.w = 1200;
  parseImageURL.query.h = 640;
  parseImageURL.query.blur = 120;
  parseImageURL.query.exp = -4;
  parseImageURL.query['mark-align'] = 'center,middle';
  parseImageURL.query['mark-w'] = 320;
  parseImageURL.query['mark-h'] = 320;
  parseImageURL.query.txt = 'FINAL FANTASY XIV / FC ASSORT';
  parseImageURL.query['txt-align'] = 'bottom,center';
  parseImageURL.query['txt-color'] = 'FFFFFF';
  parseImageURL.query['txt-font'] = 'Helvetica Neue,Bold';
  parseImageURL.query['txt-size'] = 24;
  parseImageURL.query['txt-lig'] = 0;
  parseImageURL.query['txt-pad'] = 72;
  parseImageURL.query['txt-shad'] = 0.5;
  const ogpImageURL = ogpImage || queryString.stringifyUrl(parseImageURL);

  return (
    <Helmet
      htmlAttributes={{
        metaLang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:image`,
          content: ogpImageURL,
        },
        {
          name: `og:image`,
          content: ogpImageURL,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `viewport`,
          content: `width=device-width,initial-scale=1.0,minimum-scale=1.0`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: ``,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string
}

export default SEO
